import {runLegals} from "../src/site/legals_manager.js";
import {addShivClass} from "../src/js-extensions/shivKit.js";
import {simulateDisplayContents} from "../src/js-extensions/simulateGrid.js";
import {interceptContact} from "../src/site/contact_manager.js";
import {runTalent} from "../src/site/talent_manager.js";
import {runVideos} from "../src/site/video_manager.js";
import {doWhenReady} from "../src/js-extensions/documentReady.js";
import {runDeveloperTools} from "../src/js-extensions/developerKit.js";
import {runProgramme} from "../src/site/programme_manager.js";
import {debugMe} from "../src/js-extensions/debugging.js";
import {runBooking} from "../src/site/booking_manager.js";

// When the page has loaded we can get going.
doWhenReady(function()
{
    debugMe('site_main');
    // Add in body level classes for helping old browsers.
    addShivClass('display', 'grid', 'no_grid');
    addShivClass('position', 'sticky', 'no_sticky');

    // Fix a lack of display: contents
    simulateDisplayContents('.main_nav');

    // Set up the developerKit
    runDeveloperTools();

    // Sort out the scripts to make the legals pages work.
    runLegals();

    // Process the video page if needed
    runVideos();

    // Process Contact form if needed
    interceptContact();

    // Set up the booking page if needed
    runBooking();

    // Set up the talent page if needed
    runTalent();

    runProgramme();

});
